import { globalHistory, useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { useCallback, useEffect, useState } from 'react';
import { useExitIntent as useExitIntent_ } from 'use-exit-intent';
import { getLocalStorage, setLocalStorage } from './useLocalStorage';

const useExitIntent = id => {
  const reachLocation = useLocation();
  const [showPopup, setShowPopup] = useState(false);

  const { registerHandler } = useExitIntent_({
    cookie: { key: 'exit-intent', daysToExpire: -1 },
    desktop: {
      triggerOnIdle: false,
      triggerOnMouseLeave: true,
      useBeforeUnload: false,
    },
    mobile: {
      triggerOnIdle: false,
    },
  });

  const handleExitIntent = useCallback(
    (preventRouteChange = false) => {
      const exitIntentSeen = JSON.parse(
        getLocalStorage(`exit_intent_seen_${id}`)
      );

      if (!exitIntentSeen) {
        setShowPopup(true);
        setLocalStorage(id);

        if (preventRouteChange) {
          navigate(reachLocation.pathname, { replace: true });
        }
      }
    },
    [id, reachLocation.pathname]
  );

  useEffect(() => {
    registerHandler({
      id: 'exit-intent',
      handler: handleExitIntent,
    });

    globalHistory.listen(({ action, location }) => {
      if (
        action === 'PUSH' &&
        !location.pathname.includes('/demo') &&
        !location.pathname.includes('/general-') &&
        location.pathname !== reachLocation.pathname
      ) {
        handleExitIntent(true);
      }
    });
  }, [handleExitIntent, reachLocation.pathname, registerHandler]);

  useEffect(() => {
    const handleKeydown = event => {
      if (event.key === 'Escape') {
        setShowPopup(false);
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', handleKeydown);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('keydown', handleKeydown);
      }
    };
  }, [registerHandler]);

  const closePopup = () => setShowPopup(false);

  return { showPopup, closePopup };
};

export default useExitIntent;
